<template>
    <div>

        <ConfirmationDialog v-bind="confirmationDialogParameters"
                            v-on:detach-selected-raw-materials="detachSelectedRawMaterials"
                            v-on:detach-selected-sub-products="detachSelectedSubProducts"/>

        <RecordDialog v-if="showRecordDialog" v-bind="recordDialogParameters"
                      v-on:hidden="showRecordDialog = false"
                      v-on:ok="setData"/>

        <div class="card st-framework-card" v-if="pageLoaded">

            <div class="card-header">
                <table style="border-collapse: collapse; width: 100%;">
                    <tr>
                        <template v-if="actions.selectViewAction">
                            <td style="margin: 0; padding: 0 0 0 1rem; width: 240px; border: solid 1px lightgray;">
                                <font-awesome-icon :icon="icon" size="sm" class="mr-2"/>
                                <SelectAction :action="actions.selectViewAction" :disabled="state.disabled"
                                              v-on:select-view="selectView"/>
                            </td>
                        </template>
                        <template v-else>
                            <td style="margin: 0; padding: 0 0 0 1rem; width: 240px;">
                                <font-awesome-icon class="mr-2" :icon="icon" size="sm"/>
                                {{ $i18n.tc('translations.' + name, 1).toUpperCase() }}
                            </td>
                        </template>
                        <td style="width: 38px;"/>
                        <td style="width: 22px; text-align: left;">
                            <font-awesome-icon :icon="['fas', 'lock']" size="sm" v-if="data.skellet_product"/>
                        </td>
                        <td style="margin: 0; padding: 0 10px; border: solid 1px lightgray;">
                            <SelectField :field="fields.selectField" v-on:input="productSelectInput"/>
                        </td>
                        <td style="margin: 0; padding: 0; border: solid 1px lightgray; text-align: center; width: 38px;"
                            v-if="showCreateButton">
                            <template v-for="(action,index) in actions.manageActions">
                                <ButtonAction  :key="action.name + '-' + index" :action="action" :disabled="state.disabled"
                                              v-on:create-product="createProduct"
                                              v-if="action.type === 'BUTTON' && action.placement === 'DOCUMENTHEADERRIGHT'"/>
                            </template>
                        </td>
                        <td style="margin: 0; padding: 0; width: 90px; text-align: right;">(#{{ data.id }})</td>
                        <td style="width: 1rem;"></td>
                    </tr>
                </table>
            </div>

            <div class="card-body st-fadein-content p-0">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-auto">
                                <QrCode :value="data.qr_code"/>
                            </div>
                            <div class="col">
                                <table class="table table-borderless table-sm" style="margin-bottom: 0">
                                    <tbody>
                                    <tr>
                                        <td class="p-0" style="width: 150px;"><b>{{ $i18n.tc('translations.Category', 1) }}</b></td>
                                        <td class="p-0">{{ data.product_category_id.label }}</td>
                                    </tr>
                                    <tr>
                                        <td class="p-0" style="width: 150px;"><b>{{ $i18n.tc('translations.Unit', 1) }}</b></td>
                                        <td class="p-0">{{ data.unit_id.label }}</td>
                                    </tr>
                                    <tr v-if="data.min_cost_price > 0">
                                        <td class="p-0" style="width: 150px;"><b>{{ $i18n.t('translations.Minimum Cost Price') }}</b></td>
                                        <td class="p-0">{{ data.min_cost_price }} &euro;</td>
                                    </tr>
                                    <tr>
                                        <td class="p-0" style="width: 150px;"><b>{{ $i18n.t('translations.Markup') }}</b></td>
                                        <td class="p-0">{{ data.markup }}%</td>
                                    </tr>
                                    <tr>
                                        <td class="p-0" style="width: 150px;"><b>{{ $i18n.t('translations.Type') }}</b></td>
                                        <td class="p-0">{{ data.type_label }}</td>
                                    </tr>
                                    <tr v-if="data.description_translation">
                                        <td class="p-0" style="width: 150px;"><b>{{ $i18n.t('translations.Description') }}</b></td>
                                        <td class="p-0">{{ data.description_translation }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-auto" v-if="data.inventory_product">
                                <div class="px-2 py-1" :style="{'color': (data.inventory < 0 ? 'red' : 'black')}">
                                    <b>{{ $i18n.t('translations.Inventory') }}:</b> {{ data.inventory_f }} {{ data.unit_id.label }}
                                </div>
                            </div>
                        </div>

                        <br>

                        <table class="table table-hover table-bordered table-striped table-sm" style="margin-bottom: 0;">
                            <thead>
                            <tr>
                                <th style="width: 12%; font-weight: bold;"/>
                                <th style="width: 11%; text-align: right; font-weight: bold;">
                                    <font-awesome-icon :icon="['fas', 'clock']"/>
                                </th>
                                <th style="width: 11%; text-align: right; font-weight: bold;">{{ $i18n.tc('translations.Rivet', 2) }}</th>
                                <th style="width: 11%; text-align: right; font-weight: bold;">{{ $i18n.t('translations.Weight') }}</th>
                                <th style="width: 11%; text-align: right; font-weight: bold;">{{ $i18n.t('translations.Purchase Price') }}</th>
                                <th style="width: 11%; text-align: right; font-weight: bold;">{{ $i18n.t('translations.Cost Price') }}</th>
                                <th style="width: 11%; text-align: right; font-weight: bold;">{{ $i18n.t('translations.Markup') }}</th>
                                <th style="width: 11%; text-align: right; font-weight: bold;">{{ $i18n.t('translations.Sale Price') }}</th>
                                <th style="text-align: right; font-weight: bold;">{{ $i18n.t('translations.Gross') + ' ' + $i18n.t('translations.Margin') + ' (%)' }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td class="st-total">{{ $i18n.t('translations.Basic Product') }}</td>
                                <td class="st-number">{{ data.man_hours_f }}</td>
                                <td class="st-number">{{ data.rivets_f }}</td>
                                <td class="st-number">{{ data.weight_f }}</td>
                                <td class="st-cost st-number">{{ data.purchase_price_f }}</td>
                                <td class="st-cost st-number">{{ data.cost_price_f }}</td>
                                <td class="st-margin st-number">{{ data.markup_price_f }}</td>
                                <td class="st-sale st-number">{{ data.sales_price_f }}</td>
                                <td class="st-margin st-number">{{ data.margin_f }}</td>
                            </tr>
                            <tr>
                                <td class="st-total">{{ $i18n.tc('translations.Sub Product', 2) }}</td>
                                <td class="st-number">{{ data.sub_product_man_hours_f }}</td>
                                <td class="st-number">{{ data.sub_product_rivets_f }}</td>
                                <td class="st-number">{{ data.sub_product_weight_f }}</td>
                                <td class="st-cost st-number">{{ data.sub_product_purchase_price_f }}</td>
                                <td class="st-cost st-number">{{ data.sub_product_cost_price_f }}</td>
                                <td class="st-margin st-number">{{ data.sub_product_markup_price_f }}</td>
                                <td class="st-sale st-number">{{ data.sub_product_sales_price_f }}</td>
                                <td class="st-margin st-number">{{ data.sub_product_margin_f }}</td>
                            </tr>
                            <tr>
                                <td class="st-total">{{ $i18n.t('translations.Total') }}</td>
                                <td class="st-number st-bordertop">{{ data.total_man_hours_f }}</td>
                                <td class="st-number st-bordertop">{{ data.total_rivets_f }}</td>
                                <td class="st-number st-bordertop">{{ data.total_weight_f }}</td>
                                <td class="st-cost st-number st-bordertop">{{ data.total_purchase_price_f }}</td>
                                <td class="st-cost st-number st-bordertop">{{ data.total_cost_price_f }}</td>
                                <td class="st-margin st-number st-bordertop">{{ data.total_markup_price_f }}</td>
                                <td class="st-sale st-number st-bordertop">{{ data.total_sales_price_f }}</td>
                                <td class="st-margin st-number st-bordertop">{{ data.total_margin_f }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer light" v-if="showProductCardFooter">
                        <div class="row">
                            <div class="col">
                            </div>
                            <div class="col-auto">
                                <template v-for="(action,index) in actions.manageActions">
                                    <ButtonAction :key="action.name + '-' + index" :action="action" :disabled="state.disabled"
                                                  v-on:update-product="updateProduct"
                                                  v-if="action.type === 'BUTTON' && action.placement === 'PRODUCTCARDFOOTERRIGHT'"/>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <Table :key="'sketchup-references-'+tableReload" header-class="light" footer-class="light"
                       :title="$i18n.tc('translations.SketchUp Reference',2)" :rows="data.sketchup_references" :fields="fields.sketchupReferenceFields"
                       :actions="actions.sketchupReferenceActions" :state="state"
                       collapsible :collapsed="sketchupReferencesCollapsed"
                       v-on:collapse-table="collapseSketchupReferences"
                       v-on:create-row="createSketchupReference"
                       v-on:delete-row="deleteSketchupReference"
                       v-on:update-row="updateSketchupReference"/>
                <Table :key="'sub-products-'+tableReload" header-class="light" footer-class="light"
                       :title="$i18n.tc('translations.Sub Product',2)" :rows="data.sub_products" :totals="data" :fields="fields.subProductFields"
                       :totalFields="fields.subProductTotalFields"
                       :actions="actions.subProductActions" :state="state"
                       collapsible :collapsed="subProductsCollapsed"
                       v-on:attach-row="attachSubProduct"
                       v-on:collapse-table="collapseSubProducts"
                       v-on:detach-row="detachSubProduct"
                       v-on:detach-selection="confirmDetachSelectedSubProducts"
                       v-on:detail-row="detailSubProduct"
                       v-on:manage-row="manageProduct"
                       v-on:update-row="updateSubProduct"/>
                <Table :key="'main-products-'+tableReload" header-class="light" footer-class="light"
                       :title="$i18n.tc('translations.Main Product',2)" :rows="data.main_products" :fields="fields.mainProductFields"
                       :actions="actions.mainProductActions" :state="state"
                       collapsible :collapsed="mainProductsCollapsed"
                       v-on:collapse-table="collapseMainProducts"
                       v-on:detail-row="detailMainProduct"
                       v-on:manage-row="manageProduct"/>
                <Table :key="'raw-materials-'+tableReload" header-class="light" footer-class="light"
                       :title="$i18n.tc('translations.Raw Material',2)" :rows="data.raw_materials" :fields="fields.rawMaterialFields"
                       :actions="actions.rawMaterialActions" :state="state"
                       collapsible :collapsed="rawMaterialsCollapsed"
                       v-on:attach-row="attachRawMaterial"
                       v-on:collapse-table="collapseRawMaterials"
                       v-on:detach-row="detachRawMaterial"
                       v-on:detach-selection="confirmDetachSelectedRawMaterials"
                       v-on:detail-row="detailRawMaterial"
                       v-on:manage-row="manageProduct"
                       v-on:update-row="updateRawMaterial"/>
            </div>

        </div>

    </div>
</template>

<script>
import ButtonAction from "@/components/actions/ButtonAction";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import QrCode from "@/components/QrCode.vue";
import RecordDialog from "@/components/dialogs/RecordDialog";
import SelectField from "@/components/forms/fields/SelectField.vue";
import Table from "@/components/Table";
import SelectAction from "@/components/actions/SelectAction.vue";

export default {
    name: "Product",
    components: {
        SelectAction,
        ButtonAction,
        ConfirmationDialog,
        QrCode,
        RecordDialog,
        SelectField,
        Table,
    },
    props: ['name', 'icon', 'api', 'state', 'userLanguage', 'languages'],
    data() {
        return {
            pageLoaded: false,
            actions: null,
            fields: null,
            data: null,
            showProductCardFooter: true,
            showCreateButton: false,
            mainProductsCollapsed: false,
            sketchupReferencesCollapsed: false,
            subProductsCollapsed: false,
            rawMaterialsCollapsed: false,
            confirmationDialogParameters: {
                type: '',
                title: '',
                message: '',
                ok_event: 'ok',
                ok_text: 'OK',
                cancel_event: 'cancel',
                cancel_text: 'Cancel',
                data: null,
            },
            showRecordDialog: false,
            recordDialogParameters: {
                type: null,
                title: null,
                api: null,
                fields: null,
                data: null,
                ok_label: null,
                ok_event: null,
                languages: this.languages,
                userLanguage: this.userLanguage,
            },
            tableReload: 0,
        }
    },
    watch: {
        $route() {
            this.fetchData(parseInt(this.$route.params.id));
        }
    },
    created() {
        this.fetchData(parseInt(this.$route.params.id));
    },
    methods: {
        attachRawMaterial() {
            this.recordDialogParameters.type = "create";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Raw Material", 1);
            this.recordDialogParameters.api = this.api + '/attach_raw_material/' + this.data.id;
            this.recordDialogParameters.fields = this.fields.attachRawMaterialFields;
            this.recordDialogParameters.data = null;
            this.recordDialogParameters.ok_label = this.$i18n.t("translations.Attach");
            this.recordDialogParameters.ok_event = 'ok';
            this.showRecordDialog = true;
        },
        attachSubProduct() {
            this.recordDialogParameters.type = "create";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Sub Product", 1);
            this.recordDialogParameters.api = this.api + '/attach_sub_product/' + this.data.id;
            this.recordDialogParameters.fields = this.fields.attachSubProductFields;
            this.recordDialogParameters.data = null;
            this.recordDialogParameters.ok_label = this.$i18n.t("translations.Attach");
            this.recordDialogParameters.ok_event = 'ok';
            this.showRecordDialog = true;
        },
        checkLayout() {
            this.showProductCardFooter = false;
            this.showCreateButton = false;
            this.actions.manageActions.forEach(action => {
                if (action.placement === 'PRODUCTCARDFOOTERLEFT' || action.placement === 'PRODUCTCARDFOOTERRIGHT') {
                    this.showProductCardFooter = true;
                }
                if (action.placement === 'DOCUMENTHEADERRIGHT') {
                    this.showCreateButton = true;
                }
            });
        },
        collapseMainProducts() {
            this.mainProductsCollapsed = !this.mainProductsCollapsed;
        },
        collapseRawMaterials() {
            this.rawMaterialsCollapsed = !this.rawMaterialsCollapsed;
        },
        collapseSketchupReferences() {
            this.sketchupReferencesCollapsed = !this.sketchupReferencesCollapsed;
        },
        collapseSubProducts() {
            this.subProductsCollapsed = !this.subProductsCollapsed;
        },
        confirmDetachSelectedRawMaterials() {
            this.confirmationDialogParameters.type = 'delete';
            this.confirmationDialogParameters.title = this.$i18n.tc("translations.Detach Raw Material", 2);
            this.confirmationDialogParameters.message = this.$i18n.t("translations.Are you sure you want to detach the selected raw materials?");
            this.confirmationDialogParameters.ok_event = 'detach-selected-raw-materials';
            this.confirmationDialogParameters.ok_text = this.$i18n.t("translations.Detach");
            this.confirmationDialogParameters.cancel_text = this.$i18n.t("translations.Cancel");
            this.$bvModal.show('confirmation-dialog');
        },
        confirmDetachSelectedSubProducts() {
            this.confirmationDialogParameters.type = 'delete';
            this.confirmationDialogParameters.title = this.$i18n.tc("translations.Detach Sub Product", 2);
            this.confirmationDialogParameters.message = this.$i18n.t("translations.Are you sure you want to detach the selected sub products?");
            this.confirmationDialogParameters.ok_event = 'detach-selected-sub-products';
            this.confirmationDialogParameters.ok_text = this.$i18n.t("translations.Detach");
            this.confirmationDialogParameters.cancel_text = this.$i18n.t("translations.Cancel");
            this.$bvModal.show('confirmation-dialog');
        },
        createProduct() {
            this.recordDialogParameters.type = "create";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Product", 1);
            this.recordDialogParameters.api = this.api;
            this.recordDialogParameters.fields = this.fields.productFields;
            this.recordDialogParameters.data = null;
            this.recordDialogParameters.ok_label = null;
            this.recordDialogParameters.ok_event = 'ok';
            this.showRecordDialog = true;
        },
        createSketchupReference() {
            this.recordDialogParameters.type = "create";
            this.recordDialogParameters.title = this.$i18n.tc("translations.SketchUp Reference", 1);
            this.recordDialogParameters.api = this.api + '/create_product_sketchup_reference/' + this.data.id;
            this.recordDialogParameters.fields = this.fields.sketchupReferenceFields;
            this.recordDialogParameters.data = null;
            this.recordDialogParameters.ok_label = this.$i18n.t("translations.Create");
            this.recordDialogParameters.ok_event = 'ok';
            this.showRecordDialog = true;
        },
        deleteSketchupReference(data) {
            this.recordDialogParameters.type = "delete";
            this.recordDialogParameters.title = this.$i18n.tc("translations.SketchUp Reference", 1);
            this.recordDialogParameters.api = this.api + '/delete_product_sketchup_reference/' + this.data.id;
            this.recordDialogParameters.fields = this.fields.sketchupReferenceFields;
            this.recordDialogParameters.data = data;
            this.recordDialogParameters.ok_label = null;
            this.recordDialogParameters.ok_event = 'ok';
            this.showRecordDialog = true;
        },
        detachRawMaterial(data) {
            this.recordDialogParameters.type = "delete";
            this.recordDialogParameters.title = data.label;
            this.recordDialogParameters.api = this.api + '/detach_raw_material/' + this.data.id;
            this.recordDialogParameters.fields = this.fields.productFields;
            this.recordDialogParameters.data = data;
            this.recordDialogParameters.ok_label = this.$i18n.t("translations.Detach");
            this.recordDialogParameters.ok_event = 'ok';
            this.showRecordDialog = true;
        },
        detachSelectedRawMaterials() {
            this.state.loading = true;
            let ids = [];
            this.data.raw_materials.forEach(row => {
                if (row.selected) {
                    ids.push(row.id);
                }
            });
            this.$http.post(this.api + '/detach_selected_raw_materials/' + this.data.id, {
                'raw_materials': ids
            }, {}).then((res) => {
                this.setData(res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("Product:detachSelectedRawMaterials():error:", error);
            });
        },
        detachSelectedSubProducts() {
            this.state.loading = true;
            let ids = [];
            this.data.sub_products.forEach(row => {
                if (row.selected) {
                    ids.push(row.id);
                }
            });
            this.$http.post(this.api + '/detach_selected_sub_products/' + this.data.id, {
                'sub_products': ids
            }, {}).then((res) => {
                this.setData(res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("Product:detachSelectedSubProducts():error:", error);
            });
        },
        detachSubProduct(data) {
            this.recordDialogParameters.type = "delete";
            this.recordDialogParameters.title = data.label;
            this.recordDialogParameters.api = this.api + '/detach_sub_product/' + this.data.id;
            this.recordDialogParameters.fields = this.fields.productFields;
            this.recordDialogParameters.data = data;
            this.recordDialogParameters.ok_label = this.$i18n.t("translations.Detach");
            this.recordDialogParameters.ok_event = 'ok';
            this.showRecordDialog = true;
        },
        detailMainProduct(data) {
            this.recordDialogParameters.type = "detail";
            this.recordDialogParameters.title = data.label;
            this.recordDialogParameters.api = null;
            this.recordDialogParameters.fields = this.fields.productFields;
            this.recordDialogParameters.data = data;
            this.recordDialogParameters.ok_label = null;
            this.recordDialogParameters.ok_event = 'ok';
            this.showRecordDialog = true;
        },
        detailRawMaterial(data) {
            this.recordDialogParameters.type = "detail";
            this.recordDialogParameters.title = data.label;
            this.recordDialogParameters.api = null;
            this.recordDialogParameters.fields = this.fields.productFields;
            this.recordDialogParameters.data = data;
            this.recordDialogParameters.ok_label = null;
            this.recordDialogParameters.ok_event = 'ok';
            this.showRecordDialog = true;
        },
        detailSubProduct(data) {
            this.recordDialogParameters.type = "detail";
            this.recordDialogParameters.title = data.label;
            this.recordDialogParameters.api = null;
            this.recordDialogParameters.fields = this.fields.productFields;
            this.recordDialogParameters.data = data;
            this.recordDialogParameters.ok_label = null;
            this.recordDialogParameters.ok_event = 'ok';
            this.showRecordDialog = true;
        },
        fetchData(id) {
            this.state.loading = true;
            this.pageLoaded = false;
            this.$http.get(this.api + '/manage/' + id, {}).then((res) => {
                this.setData(res.data);
                this.pageLoaded = !!this.data;
                this.state.loading = false;
            }).catch((error) => {
                this.data = null;
                console.log("Product:fetchData():error:", error);
            });
        },
        manageProduct(data) {
            this.fetchData(data.id);
        },
        productSelectInput(data) {
            if (data['id'] !== parseInt(this.$route.params.id)) {
                this.$router.push({params: {id: data['id']}});
            }
        },
        selectView(option) {
            this.$router.push({name: option.routename, params: {id: this.data.id}});
        },
        setData(data) {
            if (data.data) {
                let tmp = data.data;
                tmp.sub_products.filter(product => product.selected = false);
                tmp.sub_products.selected = false;
                tmp.main_products.filter(product => product.selected = false);
                tmp.main_products.selected = false;
                tmp.raw_materials.filter(product => product.selected = false);
                tmp.raw_materials.selected = false;
                this.data = tmp;
            } else {
                this.data = null;
            }
            this.actions = data.actions;
            this.fields = data.fields;
            this.tableReload++;
            this.checkLayout();
        },
        updateProduct() {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.data.label;
            this.recordDialogParameters.api = this.api;
            this.recordDialogParameters.fields = this.fields.productFields;
            this.recordDialogParameters.data = this.data;
            this.recordDialogParameters.ok_label = null;
            this.recordDialogParameters.ok_event = 'ok';
            this.showRecordDialog = true;
        },
        updateRawMaterial(data) {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = data.label;
            this.recordDialogParameters.api = this.api + '/update_raw_material/' + this.data.id;
            this.recordDialogParameters.fields = this.fields.updateRawMaterialFields;
            this.recordDialogParameters.data = data;
            this.recordDialogParameters.ok_label = null;
            this.recordDialogParameters.ok_event = 'ok';
            this.showRecordDialog = true;
        },
        updateSketchupReference(data) {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.$i18n.tc("translations.SketchUp Reference", 1);
            this.recordDialogParameters.api = this.api + '/update_product_sketchup_reference/' + this.data.id;
            this.recordDialogParameters.fields = this.fields.sketchupReferenceFields;
            this.recordDialogParameters.data = data;
            this.recordDialogParameters.ok_label = null;
            this.recordDialogParameters.ok_event = 'ok';
            this.showRecordDialog = true;
        },
        updateSubProduct(data) {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = data.label;
            this.recordDialogParameters.api = this.api + '/update_sub_product/' + this.data.id;
            this.recordDialogParameters.fields = this.fields.updateSubProductFields;
            this.recordDialogParameters.data = data;
            this.recordDialogParameters.ok_label = null;
            this.recordDialogParameters.ok_event = 'ok';
            this.showRecordDialog = true;
        },
    }
}
</script>

<style scoped>
</style>
